import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Projects" mdxType="Title" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://rated.network"
        }}><strong parentName="a">{`2022 - Rated Network`}</strong></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://sprout.ai"
        }}><strong parentName="a">{`2021 to 2022 - Sprout.ai`}</strong></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://drivetribe.com"
        }}><strong parentName="a">{`2016 to 2021 - DriveTribe`}</strong>{` (~5M MAUs)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=0cJ565r2FVI&t=1s"
        }}><strong parentName="a">{`2017 - Kappa Architecture`}</strong></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.crunchbase.com/organization/6tribes#section-overview"
        }}><strong parentName="a">{`2015 to 2016 - 6Tribes - acquired`}</strong></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ieeexplore.ieee.org/abstract/document/7584962"
        }}><strong parentName="a">{`2014 - Memory tuning on Spark`}</strong></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ieeexplore.ieee.org/abstract/document/7207196"
        }}><strong parentName="a">{`2014 - Machine Learning on Spark`}</strong></a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      